body {
  font-family: Mulish, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f6fa;
  font-size: 13px;
  line-height: 24px;
}
a,
a:visited,
a:-webkit-any-link {
  color: #a1a1a1;
  font-size: 12px;
  text-decoration: none;
}

section {
  margin-right: 300px;
  padding: 38px 148px 48px 148px;
  margin: auto;
  /* taille du header */
  margin-top: 54px;
}

.titleHome {
  font-weight: 300 !important;
}
.container-images {
  margin-top: 56px;
  display: flex;
  gap: 32px;
  width: 100%;
}
.container-image {
  width: calc(50vw - 220px);
  height: 360px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.image-pro {
  background-image: url(/src/images/image-pro.jpg);
}

.image-part {
  background-image: url(/src/images/image-part.jpg);
}

.red-title {
  margin-top: -48px;
  position: absolute !important;
  box-shadow: 2px 3px 13px -2px rgba(68, 68, 68, 0.3) !important;
  margin-left: -12px;
  color: white;
  font-size: 18px;
  font-family: Poppins;
  width: fit-content;
  padding: 12px 24px !important;
}

.container-btn {
  display: flex;
  justify-content: flex-end;
}

.container-btn button {
  margin-top: -16px;
  margin-right: -16px;
}

.fond {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: -1;
}

.btnSel:hover {
  background: white !important;
}

@media all and (max-width: 1000px) {
  section {
    padding: 38px 48px 48px 48px;
  }

  .container-images {
    flex-direction: column;
  }

  .container-image {
    width: calc(100vw - 220px);
  }
}

@media all and (max-width: 520px) {
  .fond {
    display: none;
  }
}
@media all and (max-width: 899px) {
  div[class*="containerNotFound"] {
    padding: 12px 32px 12px 32px;
  }
}
